<template>
  <footer class="mt-5">
    <b-container>
      <b-row class="py-3">
        <b-col sm="2">
          <router-link to="/">
            <b-img
              :src="require('@/assets/imgs/logos/red-logo.svg')"
              height="60"
            ></b-img>
          </router-link>
        </b-col>
        <b-col sm="7">
          <b-nav class="text-center">
            <b-nav-item
              v-for="link in links"
              :key="link.index"
              :to="link.path"
              class="font-bold"
              >{{ link.text }}</b-nav-item
            >
          </b-nav>
        </b-col>
        <b-col sm="3">
          <section class="contact">
            <p v-if="contacts" class="font-bold text-center">
              <!-- <span class="icon"><i class="icon-call"></i></span> -->
              <span class="mx-4 px-3">{{ $t("label.contact") }}</span>
              <span>{{ getContacts.value }}</span>
            </p>
            <template v-else>
              <content-placeholders :rounded="true">
                <content-placeholders-text :lines="2" />
              </content-placeholders>
            </template>
          </section>
        </b-col>
      </b-row>
    </b-container>
    <section class="bottom-footer">
      <p class="text-center font-regular py-3 mb-0">
        {{ $t("label.bottom_footer", { getYear }) }}
      </p>
    </section>
  </footer>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Array
    }
  },

  data() {
    return {
      links: [
        {
          text: this.$t("link.who_we_are"),
          path: "/about-us"
        },
        {
          text: this.$t("link.our_companies"),
          path: "/our-companies"
        },
        {
          text: this.$t("link.sharing_details"),
          path: "/sharing-details"
        },
        {
          text: this.$t("link.investment_invitation"),
          path: "/investments-invitation"
        },
        {
          text: this.$t("link.money_details"),
          path: "/money-details"
        }
      ]
    };
  },
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
    getContacts() {
      if (this.contacts) {
        return this.contacts[0];
      } else {
        return "";
      }
    }
  }
};
</script>
